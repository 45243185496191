import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { EnquiryHeader } from '../components/Acf/EnquiryHeader'
import PostList from '../components/Posts/PostList';
import { decodeEntities, isIE11 } from '../utils/helpers';

export const BlogIndex = (props) => {
  const { data, pageContext, location } = props;
  const { wordpressPost: page = [], allWordpressPost, oldestPostsSorted, categoryFilter, wordpressWpSettings, siteSettings } = data;
  const { blogTopForm, blogBottomForm } = siteSettings.options;
  const { yoast } = page ? page : { yoast: {} }
  const { showAuthor } = siteSettings.options
  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressPost;
  const { edges: oldestPosts } = oldestPostsSorted;
  /**
   * Use the sorted posts to pass into PostList component. We filter from the top level BlogIndex component
   */
  const [sortedPosts, setSortedPosts] = useState(posts)

  const [sortFilter, setSortFilter] = useState('')

  const [isIE, setIsIE] = useState(false)

  function handleChange(event) {
    // We need to set the select value filter value on change.
    setSortFilter('')
    setSortFilter(event.target.value);
    // Finally set the state of sorted posts to the correctly sorted array.
    sortFilter === 'Newest'
      ? setSortedPosts(posts)
      : setSortedPosts(oldestPosts)
  }


  useEffect(() => {
    if (location.state) {
      if (typeof location.state.filter != 'undefined' && !sortFilter) {
        sortFilter !== location.state.filter && setSortFilter(location.state.filter);
      }
    }
    /**
     * Don't render the stylistic span to prevent content alignment issue on IE11
     * The filter is intuitive via design to allow for it not being there.
     */
    if (isIE11) {
      setIsIE(true)
    }
    // set our sorted posts state depending on the value of our sort filter
    function initPosts() {
      // We need to initialise our sortFilter if there is no location state 
      if (!location?.state?.filter && !sortFilter) {
        setSortFilter('Newest')
      }
      sortFilter === 'Newest'
        ? setSortedPosts(posts)
        : setSortedPosts(oldestPosts)
    }
    initPosts();

  }, [sortFilter]);
  const FilterBySelector = props => {
    const { sortFilter, handleChange } = props
    return (
      <div className="filter-selector-wrapper">
        {!isIE && <span>Filter By: </span>}
        <select className="filter-selector" value={sortFilter} onChange={(event) => handleChange(event)}>
          <option defaultValue disabled>Filter By</option>
          <option value={'Newest'}>Newest</option>
          <option value={`Oldest`}>Oldest</option>
        </select>
      </div>
    )
  }

  return (
    <Layout location={location}>
      <SEO
        title={`${yoast.metaTitle ?
            yoast.metaTitle :
            `Help & Advice | ${decodeEntities(siteTitle)}`
          }`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      {blogTopForm && <EnquiryHeader {...blogTopForm} />}
      <PostList
        posts={sortedPosts}
        title="Latest posts"
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={`${wordpressWpSettings.blogSlug}/`}
        showAuthor={showAuthor}
        FilterBySelector={FilterBySelector}
        handleChange={handleChange}
        sortFilter={sortFilter}
        isBlogList={true}
        showGlobalCTA={true}
      />
      {blogBottomForm && <EnquiryHeader {...blogBottomForm} />}
    </Layout>
  )
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export default BlogIndex;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
        blogTopForm {
          actionText
          content
          formId
          subTitle
          title
        }
        blogBottomForm {
          actionText
          content
          formId
          subTitle
          title
        }
      }
    },
    wordpressPost: wordpressPage(slug: {eq: "help-and-advice"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    oldestPostsSorted: allWordpressPost(
      sort: { fields: date, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
